<template>
  <div class="template-home">
    <r-container>
      <r-row>
        <r-col class="col-6">
          <lang-select></lang-select>
        </r-col>
      </r-row>
    </r-container>
    <r-content>
      <router-view v-slot="{ Component }">
        <transition name="slide-end" mode="out-in">
          <keep-alive :exclude="['singleBlog']">
            <component :is="Component" />
          </keep-alive>
        </transition>
      </router-view>
    </r-content>
  </div>
</template>

<script>

import LangSelect from "app/extension/renus/translate/view/langSelect";
export default {
  name: "home",
  components:{LangSelect},
  data() {
    return {
    };
  }
};
</script>

<style lang="scss">
@import "~renusify/style/_include";
.r-content {
  min-height: 80vh;
}
</style>
