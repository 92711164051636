<template>
  <r-menu
    :label="$t($r.lang)"
    :items="langs"
    @update:model-value="switcher"
    :model-value="{ value: $r.lang }"
  >
    <template v-slot="{ item }">
      {{ item.name }}
    </template>
  </r-menu>
</template>

<script>
export default {
  name: "langSelect",
  data() {
    return {
      RTL: this.$r.rtl,
    };
  },
  beforeMount() {
    if (this.$storage.has("rtl")) {
      this.RTL = this.$storage.get("rtl");
    } else {
      this.RTL = true;
    }

    const lang = this.$storage.get("lang");
    if (lang) {
      this.$r.lang = lang;
    }
  },
  computed: {
    langs() {
      let r = [];
      for (let key in this.$r.langs) {
        r.push({
          value: key,
          name: this.$t(key),
          rtl: this.$r.langs[key]["rtl"],
        });
      }
      return r;
    },
  },
  methods: {
    switcher(lang) {
      if (!lang) {
        return null;
      }
      this.RTL = lang.rtl;
      this.$storage.set("lang", lang.value);
      this.$helper.setCookie("lang", lang.value, 1000 * 24 * 60 * 60);
      this.$storage.set("rtl", this.RTL);
      window.location.reload();
    },
  },
};
</script>

<style lang="scss">
@import "~renusify/style/include";
</style>
